/* eslint-disable no-irregular-whitespace */
/* eslint-disable array-bracket-spacing */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import singelgem from '../../assets/images/tower/gem.svg'
import ThemeButton from '../../master/Button'
import Inputfield from '../../master/Input'
import audio1 from '../../assets/audio/game.mp3'
import SkullHard from '../../assets/images/tower/skull-hard.svg'
import gamelose from '../../assets/audio/lose-game.wav'
import gamesound from '../../assets/audio/gameSound.mp3'
import WinningPopup from '../popup/winningPopup'
import UserContext from '../../context/UserContext'
import { towerInstance } from '../config/axios'
import './tower.css'

function Tower() {
  const navigate = useNavigate()

  const { user, setUser } = useContext(UserContext)
  const [stats, setStates] = useState([[], [], [], [], [], [], [], []])
  const [loading, setLoading] = useState(false)
  const [gameStates] = useState([...Array(8).keys()])
  const [clientSeed, setClientSeed] = useState('')
  const [gameLevel, setGameLevel] = useState({ label: 'easy', col: 4 })
  const [activeGame, setActiveGame] = useState(false)
  const [rowValue, setRowValue] = useState([...Array(4).keys()])
  const [volume, setVolume] = useState(true)
  const [betValue, setBetValue] = useState(50)
  const [lose, setLose] = useState(false)
  const [win, setWin] = useState(false)
  const [activeBox, setActiveBox] = useState(false)
  const [winvalue, setWinValue] = useState(0)
  const [lossValue, setLossValue] = useState(0)
  const [cashout, setCashout] = useState(false)
  const [loseuser, setLoseuser] = useState(false)
  const [gameStatus, setGameStatus] = useState({
    currentRound: 7,
    tileSelected: [],
    rounds: [],
  })

  const genrateClientSeed = (length = 20) => {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    setClientSeed(result)
  }

  useEffect(() => {
    genrateClientSeed()
  }, [])
  const generateGamePrint = () => {
    const stt = []
    ;[...Array(8).keys()].forEach(() => {
      let ss = []
      const items = []
      if (gameLevel.label === 'easy') {
        ss = [0, 1, 2, 3]
        ;[...Array(3).keys()].forEach(() => {
          items.push(ss[Math.floor(Math.random() * ss.length)])
          ss = ss.filter((ee) => !items.includes(ee))
        })
      } else if (gameLevel.label === 'medium') {
        ss = [0, 1, 2]
        ;[...Array(2).keys()].forEach(() => {
          items.push(ss[Math.floor(Math.random() * ss.length)])
          ss = ss.filter((ee) => !items.includes(ee))
        })
      } else {
        ss = [0, 1, 2]

        items.push(ss[Math.floor(Math.random() * ss.length)])
      }
      stt.push(items)
    })
    // setStates([...stt]);
  }

  useEffect(() => {
    const ele = document.getElementById('gamesoundplay')
    if (ele) {
      ele.currentTime = 0
      ele.play()
    }
  })

  useEffect(() => {
    const soundVal = localStorage.getItem('sound')
    if (soundVal === 'false') {
      setVolume(false)
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const getPreviousRunningGame = async () => {
      try {
        const runningGameResponse = await towerInstance().get('/runningGame')
        const { isRunning } = runningGameResponse.data
        if (isRunning && isMounted) {
          toast.success('Game restarted.', { toastId: 'restart-game' })
          const {
            tileSelected,
            gameDifficulty,
            clientSeed: dbClientSeed,
            prevResult,
            amount,
          } = runningGameResponse.data
          setBetValue(amount)
          setStates(prevResult)
          setClientSeed(dbClientSeed)
          setGameLevel(gameDifficulty)
          if (gameDifficulty === 'easy') {
            setGameLevel({ label: 'easy', col: 4 })
            setRowValue([...Array(4).keys()])
          } else if (gameDifficulty === 'medium') {
            setGameLevel({ label: 'medium', col: 3 })
            setRowValue([...Array(3).keys()])
          } else {
            setGameLevel({ label: 'hard', col: 3 })
            setRowValue([...Array(3).keys()])
          }

          setActiveGame(true)
          setLose(false)
          setWinValue(tileSelected.length)
          if (tileSelected.length) {
            setWin(true)
            setActiveBox(true)
          }

          setGameStatus({
            ...gameStatus,
            tileSelected,
            currentRound: 7 - tileSelected.length,
            rounds: [],
          })
          generateGamePrint()
          console.log('Is work')
        }
      } catch (error) {
        console.log(error)
      }
    }
    getPreviousRunningGame()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = (arg) => {
    if (activeGame) {
      toast.error('Game is running.', { toastId: 'handle-toggle' })
      return
    }

    setWin(false)
    setLose(false)
    setGameStatus({
      ...gameStatus,
      tileSelected: [],
      currentRound: 7,
      rounds: [],
    })
    setGameLevel(arg)
    if (arg === 'easy') {
      setGameLevel({ label: 'easy', col: 4 })
      setRowValue([...Array(4).keys()])
    } else if (arg === 'medium') {
      setGameLevel({ label: 'medium', col: 3 })
      setRowValue([...Array(3).keys()])
    } else {
      setGameLevel({ label: 'hard', col: 3 })
      setRowValue([...Array(3).keys()])
    }
  }

  const handleLogin = () => {
    navigate('/?modal=login')
  }

  const handleBet = async () => {
    try {
      if (!localStorage.getItem('authToken')) {
        toast.error('You need to login in order to play.', {
          toastId: 'start-game',
        })
        handleLogin()
        return
      }

      if (activeGame) {
        toast.error('Game is runing.', { toastId: 'start-game' })
        return
      }

      if (user && user.wallet < betValue) {
        toast.error('Please recharge your wallet.', { toastId: 'start-game' })
        return
      }

      if (!user) {
        toast.error('User data not found.', { toastId: 'start-game' })
        return
      }

      if (!clientSeed) {
        toast.error('Please enter client seed.', { toastId: 'start-game' })
        return
      }

      if (clientSeed.length < 5) {
        toast.error('Client seed should have more then 5 combinations.', {
          toastId: 'start-game',
        })
        return
      }

      setLoading(true)

      const res = await towerInstance().post('/createGame', {
        clientSeed,
        amount: betValue * 1,
        gameDifficulty: gameLevel.label,
      })

      setLoading(false)

      const { newGame } = res.data

      if (newGame) {
        toast.success('Game started.')
        setUser({ ...user, wallet: user.wallet - betValue })
      } else {
        toast.success('Game continued.')
      }

      console.log('Create game response ', res.data)
      setActiveGame(res.data.isRunning)
      setWin(false)
      setLose(false)
      setWinValue(0)
      setGameStatus({
        ...gameStatus,
        tileSelected: res.data.tileSelected,
        currentRound: 7 - res.data.tileSelected.length,
        rounds: [],
      })
      generateGamePrint()
    } catch (error) {
      toast.error(error.response.data.message ?? 'Failed to create game.', {
        toastId: 'start-game',
      })
      console.log(error)
    }
  }

  const handleWin = () => {
    setCashout(true)
    setTimeout(() => {
      setCashout(false)
    }, 3000)
  }

  const handleCashout = async () => {
    try {
      setLoading(true)
      const res = await towerInstance().post('/cashoutAmount')
      if (!res) {
        console.log(res)
        return
      }
      console.log(res)
      setLoading(false)
      const { winAmount } = res.data
      setUser({ ...user, wallet: user.wallet + winAmount })
      setWin(true)
      setLose(false)
      setActiveBox(false)
      setActiveGame(false)
      toast.success('Cashout successfull.', { toastId: 'cashout' })
      handleWin()
    } catch (error) {
      console.log(error.response)
      setLoading(false)
      toast.error(error.response.data.message ?? 'Some error occured.', {
        toastId: 'bet-cashout',
      })
      console.log(error)
    }
  }

  const handleLose = () => {
    setCashout(true)
    setTimeout(() => {
      setCashout(false)
    }, 3000)
  }

  const handleBox = async (row, column) => {
    console.log(gameStatus, 'llllllllllll', stats)
    try {
      const res = await (
        await towerInstance().post('/hitBlock', { hitIndex: [row, column] })
      ).data
      console.log(res)

      const { isRunning, tileSelected } = res
      if (isRunning) {
        const { currentRow, rowIndex } = res
        const prevArr = [...stats]
        prevArr[rowIndex] = currentRow
        setStates(prevArr)

        const ele = document.getElementById('gameSound')
        if (ele) {
          ele.currentTime = 0
          ele.play()
        }
        setWin(true)
        setLose(false)
        setActiveBox(true)
        const winval = winvalue + 1
        setWinValue(winval)
      } else if (!isRunning && tileSelected.length === 8) {
        const ele = document.getElementById('gameSound')
        if (ele) {
          ele.currentTime = 0
          ele.play()
        }
        const { currentRow, rowIndex, winAmount } = res
        const prevArr = [...stats]
        prevArr[rowIndex] = currentRow
        setStates(prevArr)
        setWin(true)
        setLose(false)
        setActiveBox(false)
        const winval = winvalue + 1
        setWinValue(winval)
        setActiveGame(false)
        handleWin()
        setUser({ ...user, wallet: user.wallet + winAmount })
      } else if (!isRunning) {
        const { allRow } = res
        setStates(allRow)
        handleLose()
        setActiveGame(false)
        const ele = document.getElementById('losegame')
        if (ele) {
          ele.currentTime = 0
          ele.play()
        }
        setActiveBox(false)
        setLose(true)
        setWin(false)
        setLoseuser(true)
        const lossval = betValue
        setLossValue(lossval)
      }

      setGameStatus({
        ...gameStatus,
        tileSelected: [...tileSelected],
        currentRound: 7 - tileSelected.length,
      })
      // if (tileSelected.length === 8) {
      //   // handleCashout();
      //   setActiveBox(false);
      // }
    } catch (error) {
      console.log(error)
    }

    // if (stats[row].findIndex((el) => el === column) !== -1) {
    //   const ele = document.getElementById("gameSound");
    //   if (ele) {
    //     ele.currentTime = 0;
    //     ele.play();
    //   }
    //   setWin(true);
    //   setLose(false);
    //   setActiveBox(true);
    //   const winval = winvalue + 1;
    //   setWinValue(winval);
    // } else {
    //   handleLose();
    //   const ele = document.getElementById("losegame");
    //   if (ele) {
    //     ele.currentTime = 0;
    //     ele.play();
    //   }
    //   setActiveBox(false);
    //   setLose(true);
    //   setWin(false);
    //   setLoseuser(true);
    //   const lossval = betValue;
    //   setLossValue(lossval);
    // }
    // setGameStatus({
    //   ...gameStatus,
    //   tileSelected: [...gameStatus.tileSelected, [row, column]],
    //   currentRound: Number(gameStatus.currentRound) - 1,
    // });
    // if (gameStatus.currentRound === 0) {
    //   handleCashout();
    //   setActiveBox(false);
    // }
  }

  const handleBetValue = (amt) => {
    if (activeGame) {
      toast.error('Game is running.', { toastId: 'bet-amount-error' })
      return
    }
    if (user && user.wallet < betValue) {
      toast.error('You dont have enough coin.', { toastId: 'bet-amount-error' })
      return
    }

    if (amt >= 500) {
      setBetValue(500)
    } else if (amt <= 50) {
      setBetValue(50)
    } else {
      setBetValue(amt)
    }
  }

  const handleVolume = () => {
    localStorage.setItem('sound', !volume)
    setVolume(!volume)
  }

  console.log({ gameStates })

  return (
    <>
      <div className="tower-page">
        <div className="container">
          <div className="tower-body">
            <div
              className={`tower-game-section ${
                !activeGame ? 'disable-bet' : ''
              }`}
            >
              <div className="tower-title">
                {/* ******For Tower Main Heading Start******* */}

                {!lose && !win && (
                  <div className="tower-heading">
                    <h2>Tower</h2>
                  </div>
                )}

                {/* ******For Tower Main Heading End******* */}

                {/* ******For Cash Out Heading Start******* */}

                {win && (
                  <div
                    className="cash-out"
                    role="presentation"
                    onClick={() => handleCashout()}
                  >
                    <h2>
                      Cashout at
                      <img src={singelgem} alt="gem" />
                      <span>{winvalue}x</span>
                    </h2>
                  </div>
                )}

                {/* ******For Cash Out Heading End******* */}

                {/* ******For You Lost Heading Start******* */}

                {lose && (
                  <div className="tower-lost">
                    <h2>
                      You lost
                      <img src={singelgem} alt="gem" />
                      <span>{lossValue}</span>
                    </h2>
                  </div>
                )}

                {/* ******For You Lost Heading End******* */}
                {/* winner-tower-game */}
              </div>
              <div
                className={`tower-main-box ${lose ? 'lose-tower-game' : ''}`}
              >
                {gameStates.map((i, index) => (
                  <div
                    className={`tower-list-row ${
                      gameLevel.label === 'easy' ? 'tower-list-four' : ''
                    } ${
                      index === gameStatus.currentRound
                        ? activeGame && !lose
                          ? 'winner-tower-row'
                          : ''
                        : ''
                    }`}
                    key={i}
                  >
                    {rowValue.map((ele, j) => (
                      <div
                        key={ele}
                        className={`tower-box ${
                          lose &&
                          gameStatus.tileSelected.find(
                            (tile) => tile[0] === index && tile[1] === j,
                          )
                            ? 'win-box'
                            : 'lose-box'
                        } ${
                          stats[index].find((el) => el === j) !== undefined
                            ? 'box-images'
                            : lose &&
                              gameStatus.tileSelected[
                                gameStatus.tileSelected.length - 1
                              ][0] === index &&
                              gameStatus.tileSelected[
                                gameStatus.tileSelected.length - 1
                              ][1] === j
                            ? 'red-box'
                            : ''
                        }`}
                        role="presentation"
                        onClick={() => handleBox(i, j)}
                      >
                        {stats[index].find((el) => el === j) !== undefined ? (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {/* eslint-disable-next-line max-len */}
                            {gameStatus.tileSelected.find(
                              (tiles) => tiles[0] === index && tiles[1] === j,
                            ) || lose ? (
                              <img
                                src={singelgem}
                                alt="gem"
                                s
                                className="animate__animated animate__zoomIn animate__delay-0s"
                              />
                            ) : (
                              ''
                            )}
                          </>
                        ) : lose &&
                          gameStatus.tileSelected[
                            gameStatus.tileSelected.length - 1
                          ][0] === index &&
                          gameStatus.tileSelected[
                            gameStatus.tileSelected.length - 1
                          ][1] === j ? (
                          <img
                            src={SkullHard}
                            alt="gem"
                            className="animate__animated animate__bounceOut"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div className="tower-bet-box ">
              <div className="volume-btn">
                {volume ? (
                  <i
                    className="las la-volume-up"
                    role="presentation"
                    onClick={() => handleVolume()}
                  />
                ) : (
                  <i
                    className="las la-volume-off"
                    role="presentation"
                    onClick={() => handleVolume()}
                  />
                )}
              </div>
              <div className={`box-section ${activeBox ? 'disable-bet' : ''}`}>
                <Form>
                  <Form.Group>
                    <Form.Label>Bet Amount</Form.Label>
                    <div className="bet-input pdd-200">
                      {/* <Form.Control type="number" placeholder="58.52" /> */}
                      <Inputfield
                        icon="las la-dollar-sign"
                        type="text"
                        value={betValue.toString()}
                        disabled
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Clinet seed</Form.Label>
                    <div className="bet-input pdd-200">
                      {/* <Form.Control type="number" placeholder="58.52" /> */}
                      <Inputfield
                        icon="las la-key"
                        type="text"
                        placeholder="Enter your client seed"
                        value={clientSeed}
                        handleChange={(e) => setClientSeed(e.target.value)}
                        disabled
                      />
                    </div>
                  </Form.Group>
                  <div className="roll-dice">
                    <ThemeButton
                      text="Genrate client seed"
                      handleEvent={() => genrateClientSeed()}
                    />
                  </div>
                  <div className="bet-input-btn">
                    <Button
                      onClick={() => handleBetValue(betValue / 2)}
                      className={`${betValue === 50 ? 'disable-btn' : ''}`}
                    >
                      1/2
                    </Button>
                    <Button
                      onClick={() => handleBetValue(betValue * 2)}
                      className={`${betValue >= 500 ? 'disable-btn' : ''}`}
                    >
                      2x
                    </Button>
                    <Button onClick={() => handleBetValue(500)}>Max</Button>
                  </div>
                </Form>
                <div className="level-btn">
                  <Button
                    onClick={() => handleToggle('easy')}
                    className={`${
                      gameLevel.label === 'easy' && 'active-level-btn'
                    }`}
                  >
                    Easy
                  </Button>
                  <Button
                    onClick={() => handleToggle('medium')}
                    className={`${
                      gameLevel.label === 'medium' && 'active-level-btn'
                    }`}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={() => handleToggle('hard')}
                    className={`${
                      gameLevel.label === 'hard' && 'active-level-btn'
                    }`}
                  >
                    Hard
                  </Button>
                </div>

                <div className="roll-dice">
                  <ThemeButton
                    loading={loading}
                    disable={activeGame}
                    text={
                      localStorage.getItem('authToken')
                        ? 'Place Bet'
                        : 'Login to bet'
                    }
                    handleEvent={() => {
                      if (localStorage.getItem('authToken')) {
                        handleBet()
                      } else {
                        handleLogin()
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {cashout && (
        <WinningPopup
          betValue={betValue}
          winvalue={winvalue}
          lose={loseuser}
          win={win}
        />
      )}
      <audio
        className="audio-bet-confirm"
        id="gameSound"
        muted={!volume}
        // autoPlay
        loop={false}
      >
        <source src={audio1} />
      </audio>
      <audio
        className="audio-bet-confirm"
        id="losegame"
        muted={!volume}
        // autoPlay
        loop={false}
      >
        <source src={gamelose} />
      </audio>
      <audio
        className="audio-bet-confirm"
        id="gamesoundplay"
        muted={!volume}
        autoPlay
        loop={false}
      >
        <source src={gamesound} />
      </audio>
    </>
  )
}

export default Tower
