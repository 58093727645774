import React from "react";
import PropTypes from "prop-types";
import coins from "../../assets/images/tower/coins.png";
import "./popup.css";

function WinningPopup({ winvalue, betValue, lose, win }) {
  return (
    <div className="popup-wrapper">
      <div className={`winning-popup ${win ? "win-case" : ""}`}>
        {/* <p>{win ? "Winner" : lose ? "Loser" : ""}</p> */}
        <h1>{win ? "You Won!" : lose ? "You Lose!" : ""}</h1>
        <h6>{win ? (betValue * winvalue).toFixed(2) : lose ? (betValue).toFixed(2) : 0}</h6>
        <p>
          {win
            ? "is added to your balance"
            : lose
              ? "is credited from your balance"
              : ""}
        </p>
        <div className="coins-img">
          <img src={coins} alt="coins" />
        </div>
        <div className="coins" />
      </div>
    </div>
  );
}
WinningPopup.propTypes = {
  winvalue: PropTypes.number.isRequired,
  betValue: PropTypes.number.isRequired,
  lose: PropTypes.bool.isRequired,
  win: PropTypes.bool.isRequired,
};
export default WinningPopup;
