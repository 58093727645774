import axios from 'axios'
import { server } from './keys'
export const towerInstance = () =>
  axios.create({
    baseURL: `${server}/api`,
    headers: {
      Authorization: localStorage.getItem('token')
        ? `Bearer ${localStorage.getItem('token')}`
        : '',
      'Accept-Language': localStorage.getItem('lang') ?? 'en',
    },
  })
export const userInstance = () =>
  axios.create({
    baseURL: `${server}/api`,
    headers: {
      Authorization: localStorage.getItem('token')
        ? `Bearer ${localStorage.getItem('token')}`
        : '',
      'Accept-Language': localStorage.getItem('lang') ?? 'en',
    },
  })
