import { Route, Routes, BrowserRouter } from 'react-router-dom'
import './App.css'
import Tower from './components/Game/Tower'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Tower />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
