import { createContext } from "react";

const userContextSchema = {
  user: {},
  token: "",
  setUser: () => {},
  setToken: () => {},
  setInventory: () => {},
  inventory: [],
};

const UserContext = createContext(userContextSchema);

export default UserContext;
