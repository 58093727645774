import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import "./global.css";

function Inputfield({
  placeholder,
  type,
  icon,
  className,
  handleChange,
  name,
  register,
  disabled,
  value,
  label
}) {
  return (
    <Form.Group
      className={`form-field ${icon ? "icon-added" : "no-icon"} ${className || ""}`}
      controlId="exampleForm.ControlInput1"
    >
      {console.log(";ab", label)}
      {label ? (
        <Form.Label>{label || ""}</Form.Label>
      ) : ""}
      {icon && <i className={icon} />}
      <Form.Control
        type={type}
        placeholder={placeholder || ""}
        name={name}
        onChange={handleChange}
        {...register(name)}
        disabled={disabled}
        value={value}
      />
    </Form.Group>
  );
}

Inputfield.defaultProps = {
  className: "",
  type: "text",
  name: "",
  handleChange: () => {},
  disabled: false,
  register: () => {},
  value: () => {},
  label: "",
};
Inputfield.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string.isRequired,
  register: PropTypes.func,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
};
export default Inputfield;
